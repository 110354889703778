var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4",attrs:{"aling-v":"center"}},[_c('b-col',{attrs:{"cols":"7","lg":"6"}},[_c('nav',{staticClass:"d-none d-md-inline-block ml-md-4",attrs:{"aria-label":"breadcrumb"}},[_c('route-bread-crumb')],1)])],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[(_vm.cumulativeStatistic)?_c('div',{staticClass:"qulert-widgets-stats-wrapper mb-4"},[_c('Stats',{attrs:{"details":{
        title: 'Sent',
        values: { main: _vm.formatNumeric(_vm.cumulativeStatistic.sent) },
        type: 'success',
        icon: 'check',
      }}}),_c('Stats',{attrs:{"details":{
        title: 'Delivered',
        values: { main: _vm.formatNumeric(_vm.cumulativeStatistic.delivered) },
        type: 'primary',
        icon: 'check-all',
      }}}),_c('Stats',{attrs:{"details":{
        title: 'Opened',
        values: { main: '*' },
        type: 'gradient-info',
        icon: 'eyeglasses',
      }}}),_c('Stats',{attrs:{"details":{
        title: 'CTR',
        values: { main: '*' },
        type: 'red',
        icon: 'clipboard-data',
      }}})],1):_vm._e(),_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Statistics By Application")]),_c('el-table',{attrs:{"data":_vm.tableData,"row-key":"id"}},_vm._l((_vm.tableColumns),function(column){return _c('el-table-column',_vm._b({key:column.label},'el-table-column',column,false))}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }