<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <div v-if="cumulativeStatistic" class="qulert-widgets-stats-wrapper mb-4">
        <Stats :details="{
          title: 'Sent',
          values: { main: formatNumeric(cumulativeStatistic.sent) },
          type: 'success',
          icon: 'check',
        }" />
        <Stats :details="{
          title: 'Delivered',
          values: { main: formatNumeric(cumulativeStatistic.delivered) },
          type: 'primary',
          icon: 'check-all',
        }" />
        <Stats :details="{
          title: 'Opened',
          values: { main: '*' },
          type: 'gradient-info',
          icon: 'eyeglasses',
        }" />
        <Stats :details="{
          title: 'CTR',
          values: { main: '*' },
          type: 'red',
          icon: 'clipboard-data',
        }" />
      </div>
      <card>
        <h3 slot="header" class="mb-0">Statistics By Application</h3>
        <el-table :data="tableData" row-key="id">
          <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column" />
        </el-table>
      </card>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import Stats from '@/components/qulerts/widgets/stats/index';
import pushNotificationService from '@/services/pushnotifications';
import { formatNumeric, formatPercentage } from '@/utils/format';

export default {
  components: {
    RouteBreadCrumb,
    Stats,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    this.getData();
  },
  data: () => ({
    cumulativeStatistic: null,
    tableColumns: [{
      prop: 'name',
      label: 'Name',
      minWidth: 200,
      sortable: false,
    }, {
      prop: 'total',
      label: 'Total',
      minWidth: 200,
      sortable: false,
    }, {
      prop: 'sent',
      label: 'Sent',
      minWidth: 200,
      sortable: false,
    }, {
      prop: 'delivered',
      label: 'Delivered',
      minWidth: 200,
      sortable: false,
    }],
    tableData: [],
  }),
  computed: {
    ...mapGetters(['allApplications']),
  },
  methods: {
    formatNumeric,
    formatPercentage,
    async getData() {
      const s = await pushNotificationService.pnStats(this.$route.params.id);
      this.cumulativeStatistic = s.cumulativeStatistic;

      const appIds = Object.keys(s.statisticsByApplication);
      const appMap = {};
      this.allApplications.forEach(a => {
        if (appIds.includes(a.id)) {
          appMap[a.id] = a.name;
        }
      });

      this.tableData = Object.values(s.statisticsByApplication).map(app => {
        return {
          id: app.applicationId,
          name: appMap[app.applicationId],
          total: app.total,
          sent: app.sent,
          delivered: app.delivered,
        };
      });
    },
  },
};
</script>
