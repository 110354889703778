export default {
  en: 'Any/English',
  ar: 'Arabic',
  az: 'Azerbaijani',
  bs: 'Bosnian',
  ca: 'Catalan',
  'zh-Hans': 'Chinese (Simplified)',
  'zh-Hant': 'Chinese (Traditional)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  ka: 'Georgian',
  bg: 'Bulgarian',
  de: 'German',
  el: 'Greek',
  hi: 'Hindi',
  he: 'Hebrew',
  hu: 'Hungarian',
  id: 'Indonesian',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  lv: 'Latvian',
  lt: 'Lithuanian',
  ms: 'Malay',
  nb: 'Norwegian',
  pl: 'Polish',
  fa: 'Persian',
  pt: 'Portuguese',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sv: 'Swedish',
  sr: 'Serbian',
  sk: 'Slovak',
  es: 'Spanish',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  vi: 'Vietnames',
};
