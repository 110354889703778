<template>
  <div class="pn-message">
    <div class="row">
      <div class="col-md-6">
        <base-input label="Language" name="Language" :rules="required">
          <b-form-select :options="index ? languages : []" v-model="lang">
            <template #first>
              <b-form-select-option value="en" disabled>{{ index ? '--' : 'Any/English' }}</b-form-select-option>
            </template>
          </b-form-select>
        </base-input>
        <base-input label="Title" name="Title" rules="required" v-model="content.title">
          <template slot="append">
            <EmojiPicker :o="content" field="title" />
          </template>
        </base-input>
        <base-input label="Subtitle" v-model="content.subtitle">
          <template slot="append">
            <EmojiPicker :o="content" field="subtitle" />
          </template>
        </base-input>
        <base-text-area label="Message" name="Message" rules="required" type="textarea" v-model="content.message">
          <template slot="append">
            <EmojiPicker :o="content" field="message" />
          </template>
        </base-text-area>
        <base-input v-model="image.name" label="Image" placeholder="Choose File" @click="openImageUpload"
          @keypress.prevent>
          <template slot="append">
            <b-button v-if="!!(image.name.length)" class="btn btn-secondary" variant="primary"
              @click="removeImage">Remove</b-button>
            <b-button class="btn btn-primary" variant="primary" @click="openImageUpload">Browse</b-button>
          </template>
        </base-input>
        <div v-if="isSameLaunchUrl">
          <base-input label="Launch URL Web" placeholder="https://" v-model="content.launchUrl.webUrl" />
          <base-input label="Launch URL App" placeholder="deeplink://" v-model="content.launchUrl.default" />
        </div>
        <div v-else>
          <base-input label="Launch URL" placeholder="https:// or deeplink://" v-model="content.launchUrl.default" />
        </div>
        <div class="mt--3 mb-3">
          <b-form-checkbox v-model="isSameLaunchUrl">Different URL from web/app</b-form-checkbox>
        </div>
      </div>
      <div class="col-md-6">
        <PushPreview :content="{ ...content, image }" />
      </div>
    </div>
    <imageAssetModal ref="image-asset-modal" @setImageUrl="setImage" />
  </div>
</template>

<script>
import ImageAssetModal from '@/components/common/imageAssetModal';
import EmojiPicker from '@/components/common/emojiPicker';
import PushPreview from '@/components/pushnotifications/pushPreviewV2';
import languages from '@/utils/languages';

export default {
  components: {
    ImageAssetModal,
    EmojiPicker,
    PushPreview,
  },
  props: ['payload', 'images', 'index'],
  data: () => ({
    isInit: true,
    languages: languages,
    isSameLaunchUrl: false,
    lang: 'en',
    content: {
      title: '',
      subtitle: '',
      message: '',
      launchUrl: {
        webUrl: '',
        default: '',
      },
    },
    image: {
      name: '',
      url: '',
    }
  }),
  watch: {
    payload: {
      deep: true,
      handler() {
        if (this.isInit) {
          this.applyPayload()
        }
      },
    },
    images: {
      deep: true,
      handler(val) {
        const url = val[this.index].url;
        this.setImage({
          fileName: url,
          path: url,
        });
      },
    },
    lang(val) {
      this.$emit('setLang', {
        index: this.index,
        lang: languages[val],
        val,
      });
    },
    isSameLaunchUrl(val) {
      if (!val) {
        this.content.launchUrl.webUrl = this.content.launchUrl.default;
      }
    },
    content: {
      deep: true,
      handler(val) {
        this.$emit('update', {
          index: this.index,
          key: 'content',
          value: val,
        });
      },
    },
    'content.launchUrl.default'(val) {
      if (!this.isSameLaunchUrl) {
        this.$set(this.content.launchUrl, 'webUrl', val);
      }
    },
    image: {
      deep: true,
      handler(val) {
        this.$emit('update', {
          isImage: true,
          index: this.index,
          key: 'image',
          value: val,
        });
      },
    }
  },
  methods: {
    openImageUpload() {
      this.$refs['image-asset-modal'].openModal();
    },
    setImage(image) {
      this.$refs['image-asset-modal'].openModal(false);
      this.image.name = image.fileName;
      this.image.url = image.path;
    },
    removeImage() {
      this.image.name = '';
      this.image.url = '';
    },
    applyPayload() {
      this.$set(this, 'content', {
        ...this.content,
        ...this.payload[this.index],
      });

      this.$set(this, 'lang', this.payload[this.index].name);
      this.$set(this, 'isInit', false);
    }
  }
};
</script>
