<template>
  <div class="pn-platforms">
    <div class="head">
      <b-form-checkbox v-model="isChecked" switch size="lg">
        Send to <b>Web</b>
      </b-form-checkbox>
    </div>
    <div class="content" v-if="isChecked">
      <base-input label="Icon URL" placeholder="" v-model="values.icon" />
      <!-- <base-input label="Image URL" placeholder="" v-model="values.image" /> -->
      <base-input label="Badge" placeholder="" v-model="values.badge" />
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['payload', 'checked'],
  created() {
    if (this.checked) {
      this.$emit('update', {
        option: this.option,
        values: this.values,
      });
    }
  },
  data: () => ({
    isInit: true,
    option: 'fcmWeb',
    isChecked: false,
    values: {
      icon: '',
      image: '',
      badge: '',
    }
  }),
  watch: {payload: {
      deep: true,
      handler(val) {
        if (this.isInit) {
          this.checked = !!val[this.option];

          Object.keys(this.values).forEach((key) => {
            if (val[this.option]) this.$set(this.values, key, val[this.option][key]);
          });

          this.isInit = false;
        }
      },
    },
    checked: {
      immediate: true,
      handler(val) {
        this.isChecked = val;
      },
    },
    isChecked(val) {
      this.$emit('update', {
        option: this.option,
        values: val ? this.values : null,
      });
    },
    values: {
      deep: true,
      handler(val) {
        this.$emit('update', {
          option: this.option,
          values: val,
        });
      },
    },
  },
};
</script>
