<template>
  <div>
    <b-link v-b-toggle.messageAdvanced>
      <span class="when-open mr-1"><b-icon icon="caret-up-square" /></span>
      <span class="when-closed mr-1"><b-icon icon="caret-down-square" /></span>
      Advanced Settings
    </b-link>
    <b-collapse id="messageAdvanced">
      <base-input label="Time to live (TTL)" v-model="ttl.value" class="d-block mt-3">
        <template slot="append">
          <el-select v-model="ttl.unit" filterable class="no-border w-100">
            <el-option v-for="o in ['minutes', 'hours', 'days']" :key="o" :label="o" :value="o" />
          </el-select>
        </template>
      </base-input>
      <p class="fs-14 bold mb-2">UTM Parameters</p>
      <b-row>
        <b-col cols="4"><base-input label="Source" v-model="utm.source" /></b-col>
        <b-col cols="4"><base-input label="Medium" v-model="utm.medium" /></b-col>
        <b-col cols="4"><base-input label="Campaign" v-model="utm.campaign" /></b-col>
        <b-col cols="4"><base-input label="Content" v-model="utm.content" /></b-col>
        <b-col cols="4"><base-input label="Term" v-model="utm.term" /></b-col>
      </b-row>
      <div class="form-group">
        <p class="fs-14 bold mb-2">Extra Parameters</p>
        <b-row v-for="(p, i) in parameters" :key="i">
          <b-col>
            <base-input class="w-50" v-model="p.key">
              <template slot="prepend">Key</template>
            </base-input>
          </b-col>
          <b-col>
            <base-input class="w-50" v-model="p.value">
              <template slot="prepend">Value</template>
              <template slot="append">
                <b-button variant="link" @click="removeParameter(i)">
                  <b-icon-trash />
                </b-button>
              </template>
            </base-input>
          </b-col>
        </b-row>
        <b-button variant="secondary" size="sm" @click="addExtraParameter()">
          <b-icon-plus-circle />
          Add Parameter
        </b-button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['payload'],
  data: () => ({
    isInit: true,
    ttl: {
      value: 1,
      unit: 'days'
    },
    utm: {
      source: '',
      medium: '',
      campaign: '',
      content: '',
      term: '',
    },
    parameters: [],
  }),
  watch: {
    payload: {
      deep: true,
      handler(val) {
        if (this.isInit) {
          Object.keys(this.$data).forEach((key) => {
            if (val[key]) this.$set(this, key, val[key]);
          });

          this.isInit = false;
        }
      },
    },
    ttl: {
      deep: true,
      handler(val) {
        this.$emit('update', 'ttl', val);
      },
    },
    utm: {
      deep: true,
      handler(val) {
        this.$emit('update', 'utm', val);
      },
    },
    parameters: {
      deep: true,
      handler(val) {
        this.$emit('update', 'parameters', val);
      },
    },
  },
  methods: {
    addExtraParameter() {
      this.parameters.push({
        key: '',
        value: '',
      });
    },
    removeParameter(index) {
      this.parameters.splice(index, 1);
    },
  },
};
</script>
