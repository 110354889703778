<template>
  <div>
    <b-link v-b-toggle.messagePlatform>
      <span class="when-open mr-1"><b-icon icon="caret-up-square" /></span>
      <span class="when-closed mr-1"><b-icon icon="caret-down-square" /></span>
      Platform Settings
    </b-link>
    <b-collapse id="messagePlatform">
      <pIos :payload="payload" @update="updatePlatform" :checked="isCreate ? true : !!payload.iosApp" />
      <pAndroid :payload="payload" @update="updatePlatform" :checked="isCreate ? true : !!payload.fcmApp" />
      <pHuawei :payload="payload" @update="updatePlatform" :checked="isCreate ? true : !!payload.hmsApp" />
      <pWeb :payload="payload" @update="updatePlatform" :checked="isCreate ? true : !!payload.fcmWeb" />
    </b-collapse>
  </div>
</template>

<script>
import pIos from './platforms/ios';
import pAndroid from './platforms/android';
import pHuawei from './platforms/huawei';
import pWeb from './platforms/web';

export default {
  components: {
    pIos,
    pAndroid,
    pHuawei,
    pWeb,
  },
  props: ['payload'],
  computed: {
    isCreate() {
      return !(this.$route.params.id);
    },
  },
  methods: {
    updatePlatform(val) {
      this.$emit('update', 'variants', val);
    },
  },
};
</script>
