<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb />
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <validation-observer ref="formValidator">
      <b-container fluid class="mt--6">
        <b-form>
          <card>
            <base-input label="Campaign Name" name="Campaign Name" rules="required" v-model="payload.name" />
            <p class="fs-14 bold mb-2">Target Audience</p>
            <p class="fs-14 bold mb-0" v-if="payload.audience.includes.length">Included Segments</p>
            <b-row class="justify-content-start">
              <b-col cols="auto" class="mt-1" v-for="tag in payload.audience.includes" :key="tag">
                <el-tag size="small" :closable="true" :close-transition="false"
                  @close="onSegmentRemove('includes', tag)">
                  <segment-detail :segment-id="tag" />
                </el-tag>
              </b-col>
              <b-col cols="auto">
                <b-button @click="onSegmentOpen('includes')" variant="link" size="sm">
                  <b-icon-plus-circle /> Add Segment
                </b-button>
              </b-col>
            </b-row>
            <div v-if="payload.audience.includes.length">
              <p class="fs-14 bold mt-2 mb-0">Excluded Segments</p>
              <b-row class="justify-content-md-left">
                <b-col cols="auto" class="mt-1" v-for="tag in payload.audience.excludes" :key="tag">
                  <el-tag size="small" :closable="true" :close-transition="false"
                    @close="onSegmentRemove('excludes', tag)">
                    <segment-detail :segment-id="tag" />
                  </el-tag>
                </b-col>
                <b-col>
                  <b-button @click="onSegmentOpen('excludes')" variant="link" size="sm">
                    <b-icon-dash-circle /> Exclude Segment
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <p class="fs-14 mt-2 mb-0" v-if="flow.totalPushTokenCount">
              According to these selected criteria, this campaign is expected to be delivered approx.
              {{ flow.totalPushTokenCount | formatNumeric }} mobile device.
            </p>
            <segment-list ref="includesSegmentList" @on-segment-selected="onSegmentInc"
              :selected-segments="payload.audience.includes" />
            <segment-list ref="excludesSegmentList" @on-segment-selected="onSegmentExc"
              :selected-segments="payload.audience.excludes" />
          </card>
          <card no-body>
            <h4 class="px-4 pt-4 mb-0">Message</h4>
            <b-tabs v-model="flow.message.tabIndex" card class="tab-w-space">
              <b-tab v-for="(lang, index) in flow.message.languages" :key="`langs-tab-${lang.id}`">
                <template #title>
                  <span class="pr-2">{{ lang.name }}</span>
                  <button v-if="lang.id !== 0" type="button" class="close"
                    @click.prevent="removeContent(index)"><span>×</span></button>
                </template>
                <Message :payload="flow.content" :images="flow.images" :index="index" @setLang="setLang"
                  @update="updateContent" />
              </b-tab>
              <b-col>
                <b-col md="6" class="mt--100 pb-50">
                  <Platform :payload="flow.platformSettings" @update="update" />
                  <Advanced :payload="payload" @update="update" class="mt-4" />
                </b-col>
              </b-col>
              <template #tabs-end>
                <b-nav-item @click="addMessageLanguage">+</b-nav-item>
              </template>
            </b-tabs>
          </card>
          <card>
            <p class="fs-14 bold mb-0">Timing</p>
            <b-form-radio-group v-model="flow.timing.scheduleType">
              <b-form-radio value="IMMEDIATELY">{{ $t('pushNotification.scheduleType.immediately') }}</b-form-radio>
              <b-form-radio value="SCHEDULED">{{ $t('pushNotification.scheduleType.scheduled') }}</b-form-radio>
            </b-form-radio-group>
            <b-row v-if="flow.timing.scheduleType === 'SCHEDULED'" class="pt-3">
              <b-col cols="4">
                <base-input v-model="flow.timing.startDate" type="datetime-local" name="Send At" label="Send At"
                  :rules="{ isDateBetween: Object.values(flow.timing.scheduleLimit), required: true }"
                  :min="flow.timing.scheduleLimit.min" :max="flow.timing.scheduleLimit.max" />
                <small>
                  Your push notification will be sent at the time you specify {{ flow.timing.currentTimeZone }}.
                </small>
              </b-col>
            </b-row>
          </card>
          <b-button variant="primary" @click="onSubmit(false)">
            <b-icon-play />
            {{ $t('pushNotification.sendNotification') }}
          </b-button>
          <b-button variant="link" @click="onSubmit(true)">
            {{ $t('pushNotification.saveAsDraft') }}
          </b-button>
        </b-form>
      </b-container>
    </validation-observer>
  </div>
</template>

<script>
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import SegmentDetail from '@/components/segments/segmentDetail';
import SegmentList from '@/components/segments/segmentList';
import pushnotifications from '@/services/pushnotifications';
import { daysAfter, fromISO, toIsoDateTime, fromISOToUTC0ISO } from '@/utils/date';
import swal from 'sweetalert2';
import { Tag } from 'element-ui';
import { DateTime } from 'luxon';
import { extend } from 'vee-validate';
import Advanced from './components/advanced';
import Message from './components/message';
import Platform from './components/platform';
import languages from '@/utils/languages';

const segmentTypes = {
  includes: 'excludes',
  excludes: 'includes',
};

export default {
  components: {
    [Tag.name]: Tag,
    RouteBreadCrumb,
    SegmentList,
    SegmentDetail,
    Message,
    Advanced,
    Platform,
  },
  created() {
    if (!this.isCreate) this.fetchDetail();
  },
  data: () => ({
    flow: {
      message: {
        tabIndex: 0,
        languages: [{
          id: 0,
          name: 'Any/English',
        }],
      },
      content: [{
        name: 'en',
      }],
      timing: {
        scheduleType: 'IMMEDIATELY',
        startDate: null,
        currentTimeZone: DateTime.now().offsetNameShort,
        scheduleLimit: {
          min: toIsoDateTime(DateTime.now()).substring(0, 16),
          max: daysAfter(15, DateTime.now()).substring(0, 16),
        },
      },
      images: [],
      platformSettings: {},
      parameters: [],
      totalPushTokenCount: 0,
    },
    payload: {
      name: '',
      trigger: {
        type: 'IMMEDIATELY',
        sendingEndDate: null,
        recurringExpression: null,
      },
      audience: {
        includes: [],
        excludes: [],
      },
      ttl: null,
      utm: null,
      parameters: [],
      variants: [{}],
    },
  }),
  computed: {
    isCreate() {
      return !(this.$route.params.id);
    },
  },
  watch: {
    'payload.audience': {
      deep: true,
      handler() {
        this.calculatePushTokenCount();
      },
    },
    'flow.platformSettings': {
      deep: true,
      handler(val) {
        console.log('platformSettings', val);
      },
    },
  },
  methods: {
    addMessageLanguage() {
      const id = new Date().getTime();

      this.flow.content.push({
        name: `c-${id}`,
      });

      this.flow.message.languages.push({
        id,
        name: 'Another Language',
      });

      setTimeout(() => {
        [...document.querySelectorAll('.tab-w-space li[role="presentation"] a')].at(-1).click();
      });
    },
    setLang({ index, lang, val }) {
      if (index && lang && val) {
        this.$set(this.flow.message.languages[index], 'name', lang);
        this.$set(this.flow.content[index], 'name', val);
      }
    },
    removeContent(index) {
      this.flow.content.splice(index, 1);
      this.flow.message.languages.splice(index, 1);
    },
    updateContent({ index, isImage = false, key, value }) {
      if (index > -1) {
        if (isImage) {
          this.$set(this.flow.images, index, {
            ...value,
            lang: this.flow.content[index].name,
          });
          return;
        }

        this.$set(this.flow.content[index], key, { ...value, index });
      }
    },
    update(field, value) {
      if (field === 'variants') {
        // Platform Settings.
        this.$set(this.flow.platformSettings, value.option, value.values);
      } else {
        this.$set(this.payload, field, value);
      }
    },
    async calculatePushTokenCount() {
      if (!this.payload.audience.includes.length) {
        this.flow.totalPushTokenCount = 0;
        return;
      }

      const result = await pushnotifications.getTokenCount({
        includedSegmentsIds: this.payload.audience.includes,
        excludedSegmentIds: this.payload.audience.excludes,
      });

      this.flow.totalPushTokenCount = result.count;
    },
    onSegmentOpen(type) {
      this.$refs[`${type}SegmentList`].openEvent(this.payload.audience[segmentTypes[type]]);
    },
    onSegmentInc(ids) {
      this.$set(this.payload.audience, 'includes', ids);
    },
    onSegmentExc(ids) {
      this.$set(this.payload.audience, 'excludes', ids);
    },
    onSegmentRemove(type, segment) {
      const index = this.payload.audience[type].findIndex(item => item === segment);
      if (index > -1) this.payload.audience[type].splice(index, 1);
    },
    async onSubmit(isDraft = false) {
      const isValid = await this.$refs.formValidator.validate();
      if (!isValid) return;

      const id = this.$route.params.id;
      const body = JSON.parse(JSON.stringify(this.payload));
      body.variants[0].content = {};

      this.flow.content.forEach(({ name, content }) => {
        const img = this.flow.images.find(i => i.lang === name);
        const lang = {
          ...content,
          ...JSON.parse(JSON.stringify(this.flow.platformSettings)),
        };

        if (img?.url) {
          if (lang.iosApp) lang.iosApp.contentUrl = img.url;
          if (lang.fcmApp) lang.fcmApp.bigImage = img.url;
          if (lang.hmsApp) lang.hmsApp.bigImage = img.url;
          if (lang.fcmWeb) lang.fcmWeb.image = img.url;
        }

        delete lang.content;
        delete lang.index;

        body.variants[0].content[name] = lang;
      });

      if (this.flow.timing.scheduleType !== 'IMMEDIATELY') {
        body.trigger.type = 'SCHEDULED';
        body.trigger.sendingStartDate = fromISOToUTC0ISO(fromISO(this.flow.timing.startDate));
      }

      body.variants[0].parameters = body.parameters;
      body.variants[0].utm = body.utm;

      if (isDraft) {
        body.trigger = null;
      }

      if (this.isCreate) {
        await pushnotifications.pnCreate(body).then(() => {
          this.callSuccess();
        });
        return;
      }

      await pushnotifications.pnUpdate(id, {
        status: isDraft ? 'DRAFT' : 'READY',
        meta: body,
      }).then(() => {
        this.callSuccess();
      });
    },
    async fetchDetail() {
      const id = this.$route.params.id;
      const details = await pushnotifications.pnDetails(id);

      this.$set(this, 'payload', details.meta);
      this.setMessageContent();
      this.setTiming();
    },
    setMessageContent() {
      const variant = this.payload.variants[0];
      const langs = variant.content;
      const flow = {
        content: [],
        languages: [],
      };

      Object.keys(langs).forEach((name, index) => {
        flow.content.push({ name, ...langs[name], index });
        flow.languages.push({ id: index, name: languages[name] });
      });

      this.$set(this.flow.message, 'languages', flow.languages);

      setTimeout(() => {
        this.$set(this.flow, 'content', flow.content);

        const images = flow.content.map((content) => {
          const img = {
            lang: content.name,
            url: null,
          };

          if (content.iosApp?.contentUrl) img.url = content.iosApp.contentUrl;
          if (!img.url?.length && content.fcmApp?.bigImage) img.url = content.fcmApp.bigImage;
          if (!img.url?.length && content.hmsApp?.bigImage) img.url = content.hmsApp.bigImage;
          if (!img.url?.length && content.fcmWeb?.image) img.url = content.fcmWeb.image;

          return img;
        });

        this.$set(this.flow, 'images', images);
        this.setPlatformSettings();
      });
    },
    setTiming() {
      this.$set(this.flow, 'timing', {
        ...this.flow.timing,
        ...this.payload.trigger,
      });
    },
    setPlatformSettings() {
      const { iosApp, fcmApp, hmsApp, fcmWeb } = this.payload.variants[0].content['en'];
      this.$set(this.flow, 'platformSettings', { iosApp, fcmApp, hmsApp, fcmWeb });
    },
    callSuccess() {
      swal.fire({
        title: `Success`,
        confirmButtonClass: 'btn btn-success',
        icon: 'success'
      });

      this.$router.push('/channel/push-notifications');
    },
  },
};

extend('isDateBetween', (value, [min, max]) => {
  const date = DateTime.fromISO(value).valueOf();
  return date >= DateTime.fromISO(min).valueOf() && date <= DateTime.fromISO(max).valueOf();
}, {
  paramNames: ['min', 'max']
});
</script>
