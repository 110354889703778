<template>
  <div>
    <base-header class="pb-6">
      <b-row aling-v="center" class="py-4">
        <b-col cols="7" lg="6">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <validation-observer ref="formValidator">
        <b-row>
          <b-col>
            <card>
              <b-row>
                <b-col cols="6">
                  <base-input label="Campaign Name"
                              name="Campaign Name"
                              rules="required"
                              placeholder="Campaign Name"
                              v-model="inAppNotification.name">
                  </base-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-card sub-title="Message Content">
                    <b-row>
                      <b-col cols="7">
                        <b-row>
                          <b-col cols="12">
                            <div class="ml-2">
                              <b-form-group label="Position">
                                <b-form-radio-group
                                  v-model="meta.position"
                                  :options="positions"
                                  disabled-field="notEnabled">
                                </b-form-radio-group>
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <drop-list :items="meta.designElements"
                                       @reorder="$event.apply(meta.designElements)">
                              <template v-slot:item="{item, reorder}">
                                <drag :key="item.id" :data="item">
                                  <div class="mt-3">
                                    <inAppLinkButton :remove="remove" :copy="copy" :component="item"
                                                     v-if="item.type === 'LinkButton'"></inAppLinkButton>
                                    <inAppText :remove="remove" :copy="copy" :component="item"
                                               v-if="item.type === 'Text'"></inAppText>
                                    <inAppImage :remove="remove" :copy="copy" :component="item"
                                                v-if="item.type === 'Image'"></inAppImage>
                                  </div>
                                </drag>
                              </template>
                            </drop-list>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col sm="3">
                            <b-dropdown text="Add Block" variant="outline-primary" size="sm">
                              <template #button-content>
                                <b-icon-plus-circle></b-icon-plus-circle>
                                Add Block
                              </template>
                              <b-dropdown-item @click="addComponent('Image')">
                                <b-icon-image></b-icon-image>
                                Image
                              </b-dropdown-item>
                              <b-dropdown-item @click="addComponent('Text')">
                                <b-icon-type></b-icon-type>
                                Text
                              </b-dropdown-item>
                              <b-dropdown-item @click="addComponent('LinkButton')">
                                <b-icon-menu-button></b-icon-menu-button>
                                Button
                              </b-dropdown-item>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                        <b-row class="mt-3">
                          <b-col>
                            <b-card>
                              <b-row class="mb-2">
                                <b-col cols="5">
                                  <div class="heading1-title"> Page Setup</div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="6">
                                  <text-color-picker label="Background Color"
                                                     :component-style="meta"></text-color-picker>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col cols="12">
                                  <div class="body2-title">Close Button</div>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col sm="3">
                                  <b-form-group label="Height">
                                    <b-input-group size="sm" append="px">
                                      <b-form-input type="number"
                                                    v-model="meta.closeButton.height"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="3">
                                  <b-form-group label="Width">
                                    <b-input-group size="sm" append="px">
                                      <b-form-input type="number"
                                                    v-model="meta.closeButton.width"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col sm="4">
                                  <text-color-picker label="Color"
                                                     :component-style="meta.closeButton"></text-color-picker>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="5">
                        <inAppMobilePreview :meta="meta"></inAppMobilePreview>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-card sub-title="Target Audience">
                    <div class="mb-3">
                      <b-form-radio-group v-model="inAppNotification.audienceType" stacked>
                        <b-form-radio v-model="inAppNotification.audienceType" value="allUsers">
                          {{ $t('inAppNotification.audienceType.allUsers') }}
                        </b-form-radio>
                        <b-form-radio v-model="inAppNotification.audienceType" value="segment">
                          {{ $t('inAppNotification.audienceType.segment') }}
                        </b-form-radio>
                      </b-form-radio-group>
                    </div>
                    <div v-if="inAppNotification.audienceType === 'segment'">
                      <b-row>
                        <b-col cols="12">
                          <segment-list ref="selectedSegmentList"
                                        :selected-segments="meta.audience.selectedSegments"></segment-list>
                          <segment-list ref="excludedSegmentList"
                                        :selected-segments="meta.audience.excludedSegments"></segment-list>
                          <div class="heading1-title mb-3">Included Segments
                            <span v-if="meta.audience.selectedSegments.length > 0">
                      ({{ meta.audience.selectedSegments.length }})
                    </span>
                          </div>
                          <b-list-group size="sm" class="mb-3 ml-2">
                            <b-list-group-item
                              v-if="meta.audience.selectedSegments.length > 0">
                              <b-row no-gutters>
                                <b-col cols="7" align-self="center"><b>Segment Name</b></b-col>
                                <b-col cols="3" align-self="center">
                                  <b>Total Users</b>
                                </b-col>
                                <b-col cols="2" align-self="center">
                                </b-col>
                              </b-row>
                            </b-list-group-item>
                            <b-list-group-item v-for="segment in meta.audience.selectedSegments"
                                               :key="segment.id">
                              <b-row no-gutters class="p-0">
                                <b-col cols="7" align-self="center">{{ segment.name }}</b-col>
                                <b-col cols="3" align-self="center">
                                  {{ segment.totalUsers | formatNumeric }}
                                </b-col>
                                <b-col cols="2" align-self="center">
                                  <div class="float-right">
                                    <b-button size="xs" variant="link">
                                      <b-icon-trash @click="removeSelectedSegments(segment)"></b-icon-trash>
                                    </b-button>
                                  </div>
                                </b-col>
                              </b-row>
                            </b-list-group-item>
                          </b-list-group>
                          <b-button @click="openSegmentList()" variant="link" size="sm">
                            <b-icon-plus-circle></b-icon-plus-circle>
                            Include Segment
                          </b-button>
                          <div v-if="meta.audience.selectedSegments.length > 0">
                            <div class="heading1-title mb-3 mt-2">
                              Excluded Segments
                              <span v-if="meta.audience.excludedSegments.length > 0">
                      ({{ meta.audience.excludedSegments.length }})
                    </span>
                            </div>
                            <b-list-group class="mb-3  ml-2">
                              <b-list-group-item v-for="segment in meta.audience.excludedSegments"
                                                 :key="segment.id">
                                <b-row no-gutters>
                                  <b-col cols="7" align-self="center">{{ segment.name }}</b-col>
                                  <b-col cols="3" align-self="center">
                                    {{ segment.totalUsers | formatNumeric }}
                                  </b-col>
                                  <b-col cols="2" align-self="center">
                                    <div class="float-right">
                                      <b-button size="xs" variant="link">
                                        <b-icon-trash @click="removeExcludedSegments(segment)"></b-icon-trash>
                                      </b-button>
                                    </div>
                                  </b-col>
                                </b-row>
                              </b-list-group-item>
                            </b-list-group>
                            <b-button @click="openExcludedSegmentList()" variant="link" size="sm">
                              <b-icon-dash-circle></b-icon-dash-circle>
                              Exclude Segment
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-card sub-title="Rules">
                    <b-row>
                      <b-col cols="12">
                        <div v-for="(rule, index) in meta.rules" :key="`rule_${index}`">
                          <b-row class="border border-2 p-2 ml-2 mr-1 mb-2 align-items-center">
                            <b-col cols="11">
                              <page-type-rule v-if="rule.type === 'pageType'" :rule="rule"></page-type-rule>
                              <device-language-rule v-if="rule.type === 'deviceLanguage'"
                                                    :rule="rule"></device-language-rule>
                              <application-version-rule v-if="rule.type === 'applicationVersion'"
                                                        :rule="rule"></application-version-rule>
                              <country-rule v-if="rule.type === 'country'" :rule="rule"></country-rule>
                            </b-col>
                            <b-col cols="1">
                              <div class="float-left">
                                <b-button size="sm" variant="link" @click="deleteRule(index)">
                                  <b-icon-trash></b-icon-trash>
                                </b-button>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-dropdown text="Add Rule" variant="outline-primary" size="sm" class="mb-2 ml-2">
                          <template #button-content>
                            <b-icon-plus-circle></b-icon-plus-circle>
                            Add Rule
                          </template>
                          <b-dropdown-item @click="addRule('pageType')">
                            Page Type
                          </b-dropdown-item>
                          <b-dropdown-item @click="addRule('deviceLanguage')">
                            Device Language
                          </b-dropdown-item>
                          <b-dropdown-item @click="addRule('applicationVersion')">
                            Application Version
                          </b-dropdown-item>
                          <b-dropdown-item @click="addRule('country')">
                            Country
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-card sub-title="Timing">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Display Frequency">
                          <b-form-radio-group size="sm" v-model="meta.frequency.type">
                            <b-form-radio value="onlyOnce">{{
                                $t('inAppNotification.frequency.onlyOnce')
                              }}
                            </b-form-radio>
                            <b-form-radio value="manyTimes">{{
                                $t('inAppNotification.frequency.manyTimes')
                              }}
                            </b-form-radio>
                            <b-form-radio value="periodic">{{
                                $t('inAppNotification.frequency.periodic')
                              }}
                            </b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                        <div v-show="meta.frequency.type === 'manyTimes'" class="mb-3">
                          <b-form-group label="Times">
                            <b-row>
                              <b-col cols="4">
                                <b-form-input size="sm" v-model="meta.frequency.info.value"
                                              type="number"></b-form-input>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </div>
                        <div v-show="meta.frequency.type === 'periodic'" class="mb-3">
                          <b-form-group label="Show Every">
                            <b-row>
                              <b-col cols="4">
                                <b-input-group>
                                  <b-form-input size="sm" v-model="meta.frequency.info.value"
                                                type="number"></b-form-input>
                                  <b-input-group-append>
                                    <b-form-select size="sm" v-model="meta.frequency.info.unit"
                                                   :options="['minutes','hours', 'days', 'weeks']"></b-form-select>
                                  </b-input-group-append>
                                </b-input-group>
                              </b-col>
                            </b-row>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="8">
                        <dateTimePicker :selected-date="meta.startDate" label="Start Date & Time"></dateTimePicker>
                        <dateTimePicker :selected-date="meta.expireDate" label="Expire Date & Time"></dateTimePicker>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-modal size="lg" ref="modal-in-app-notification" title="Confirm Message Details">
                    <template #modal-footer="{ ok, cancel }">
                      <b-button v-if="!inAppNotification.id" size="sm" variant="primary" @click="saveAndStart(true)">
                        <b-icon-play></b-icon-play>
                        Start In App Message
                      </b-button>
                    </template>
                    <b-row>
                      <b-col cols="5">{{ $t('common.campaignName') }}</b-col>
                      <b-col cols="7">{{ inAppNotification.name }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="5">{{ $t('common.displayFrequency') }}</b-col>
                      <b-col cols="7">
                        {{ $t(`inAppNotification.frequency.${meta.frequency.type}`) }}
                        <span v-if="meta.frequency.type === 'periodic'">
                  - {{
                            $t('common.every', { every: `${meta.frequency.info.value} ${meta.frequency.info.unit}` })
                          }}
                </span>
                        <span v-else-if="meta.frequency.type === 'manyTimes'"> - {{
                            $t('common.times', { times: meta.frequency.info.value })
                          }}
                </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="5">{{ $t('common.targetAudience') }}</b-col>
                      <b-col cols="7">
                        {{ $t(`inAppNotification.audienceType.${inAppNotification.audienceType}`) }}
                        <b-row class="align-items-center"
                               v-for="eachSegment in meta.audience.selectedSegments" :key="eachSegment.id">
                          <b-col>
                            <b-icon-plus-circle variant="secondary"></b-icon-plus-circle>
                            {{ eachSegment.name }} ({{ eachSegment.totalUsers | formatNumeric }})
                          </b-col>
                        </b-row>
                        <b-row class="align-items-center"
                               v-for="eachSegment in meta.audience.excludedSegments" :key="eachSegment.id">
                          <b-col>
                            <b-icon-dash-circle variant="secondary"></b-icon-dash-circle>
                            {{ eachSegment.name }} ({{ eachSegment.totalUsers | formatNumeric }})
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="5">{{ $t('common.startDate') }}</b-col>
                      <b-col cols="7">{{ inAppNotification.startDate | formatISODateTime }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="5">{{ $t('common.endDate') }}</b-col>
                      <b-col cols="7">{{ inAppNotification.expireDate | formatISODateTime }}</b-col>
                    </b-row>
                  </b-modal>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-card>
                    <b-row>
                      <b-col>
                        <b-button v-if="inAppNotification.id" variant="primary" @click="saveAndStart(false)">Save
                        </b-button>
                        <b-button v-if="!inAppNotification.id" size="sm" variant="primary" type="submit">
                          <b-icon-play></b-icon-play>
                          Start In App Message
                        </b-button>
                        <b-button v-if="!inAppNotification.id" variant="link" @click="saveAndStart(false)">Save as Draft
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
  </div>
</template>
<script>

import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { Drag, DropList } from 'vue-easy-dnd';
import { DateTime } from 'luxon';
import randomUtils from '../../utils/randomUtils';
import { encodeBase64, decodeBase64, encodeBase64Json } from '../../utils/encodingUtils';
import inAppLinkButton from '../../components/inappnotifications/inAppLinkButton';
import inAppImage from '../../components/inappnotifications/inAppImage';
import inAppText from '../../components/inappnotifications/inAppText';
import inAppMobilePreview from '../../components/inappnotifications/mobilePreview';
import textColorPicker from '../../components/inappnotifications/textColorPicker';
import segmentList from '../../components/segments/segmentList';
import pageTypeRule from '../../components/inappnotifications/pageTypeRule';
import deviceLanguageRule from '../../components/inappnotifications/deviceLanguageRule';
import countryRule from '../../components/inappnotifications/countryRule';
import applicationVersionRule from '../../components/inappnotifications/applicationVersionRule';
import inAppNotificationsPreview from '../../services/inappnotificationsPreview';
import inAppNotifications from '../../services/inappnotifications';
import dateTimePicker from '../../components/common/dateTimePicker';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue';

export default {
  mixins: [validationMixin],
  components: {
    RouteBreadCrumb,
    deviceLanguageRule,
    applicationVersionRule,
    countryRule,
    pageTypeRule,
    textColorPicker,
    inAppMobilePreview,
    inAppLinkButton,
    inAppImage,
    inAppText,
    segmentList,
    DateTime,
    Drag,
    DropList,
    randomUtils,
    inAppNotificationsPreview,
    encodeBase64,
    decodeBase64,
    encodeBase64Json,
    inAppNotifications,
    dateTimePicker,
  },
  created() {
    this.getCampaignDetails();
  },
  beforeRouteLeave(to, from, next) {
    // if (!this.inAppNotification.id) {
    //   this.$confirm(
    //     {
    //       message: 'Do you really want to leave? you have unsaved changes!',
    //       button: {
    //         no: 'No',
    //         yes: 'Yes',
    //       },
    //       callback: (confirm) => {
    //         next(confirm);
    //       },
    //     },
    //   );
    // } else {
      next(true);
    // }
  },
  validations: {
    inAppNotification: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  data: () => ({
    positions: ['top', 'center', 'bottom'],
    meta: {
      startDate: {
        date: DateTime.now()
          .toISODate(),
        time: DateTime.now()
          .toFormat('HH:mm:ss'),
        dateTime: DateTime.now(),
      },
      expireDate: {
        date: undefined,
        time: undefined,
        dateTime: undefined,
      },
      rules: [],
      frequency: {
        type: 'onlyOnce',
        info: {
          value: 1,
          unit: 'hours',
        },
      },
      audience: {
        selectedSegments: [],
        excludedSegments: [],
      },
      position: 'center',
      color: '#fff',
      closeButton: {
        width: 10,
        height: 10,
        color: '#000000',
      },
      designElements: [],
    },
    inAppNotification: {
      name: '',
      contentType: 'html',
      audienceType: 'allUsers',
      startDate: undefined,
      expireDate: undefined,
      html: '',
      style: '',
      meta: '',
    },
  }),
  methods: {
    async getCampaignDetails() {
      const campaignId = this.$route.params.id;
      if (campaignId !== 'create') {
        const campaignDetail = await inAppNotifications.getById(campaignId);
        this.inAppNotification = campaignDetail;
        this.meta = decodeBase64(campaignDetail.meta);
      } else {
        this.addComponent('Image');
        this.addComponent('Text');
        this.addComponent('LinkButton');
      }
    },
    async saveAndStart(startImmediately) {
      this.validate();
      if (this.inAppNotification.id) {
        await inAppNotifications.update(this.inAppNotification.id, this.inAppNotification);
      } else {
        if (startImmediately) {
          this.inAppNotification.status = 'in-progress';
        }
        const result = await inAppNotifications.create(this.inAppNotification);
        this.inAppNotification.id = result.id;
      }
      await this.$router.push({ name: 'InAppNotificationsList' });
    },
    validate() {
      this.$v.inAppNotification.$touch();
      if (this.$v.inAppNotification.$anyError) {
        window.scrollTo(0, 0);
        return false;
      }
      this.inAppNotification.startDate = this.meta.startDate.dateTime;
      this.inAppNotification.expireDate = this.meta.expireDate.dateTime;
      this.inAppNotification.html = encodeBase64(inAppNotificationsPreview.render(this.meta));
      this.inAppNotification.meta = encodeBase64Json(this.meta);
      return true;
    },
    onSubmit() {
      if (this.validate()) {
        this.$refs['modal-in-app-notification'].show();
      }
    },
    validateState(name) {
      const {
        $dirty,
        $error,
      } = this.$v.inAppNotification[name];
      return $dirty ? !$error : null;
    },
    deleteRule(index) {
      this.meta.rules.splice(index, 1);
    },
    addRule(ruleType) {
      if (this.meta.rules.filter(item => item.type === ruleType).length === 0) {
        this.meta.rules.push({
          type: ruleType,
          values: [],
          condition: 'in',
        });
      }
    },
    removeSelectedSegments(segment) {
      const index = this.meta.audience.selectedSegments.findIndex(item => item.id === segment.id);
      if (index > -1) {
        this.meta.audience.selectedSegments.splice(index, 1);
      }
    },
    removeExcludedSegments(segment) {
      const index = this.meta.audience.excludedSegments.findIndex(item => item.id === segment.id);
      if (index > -1) {
        this.meta.audience.excludedSegments.splice(index, 1);
      }
    },
    openSegmentList() {
      this.$refs.selectedSegmentList.openEvent();
    },
    openExcludedSegmentList() {
      this.$refs.excludedSegmentList.openEvent();
    },
    addComponent(type) {
      const id = randomUtils.randomAlphabetic(5);
      if (type === 'LinkButton') {
        this.meta.designElements.push({
          id: id,
          text: 'Click Here',
          type: 'LinkButton',
          link: undefined,
          style: {
            color: '#4696ff',
            border: {
              color: '#4696ff',
              weight: 0,
              radius: 5,
            },
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            text: {
              fontFamily: 'System Font (Default)',
              fontSize: 16,
              fontWeight: undefined,
              fontStyle: undefined,
              textDecoration: undefined,
              align: 'center',
              color: '#FFF',
            },
          },
        });
      }
      if (type === 'Text') {
        this.meta.designElements.push({
          id: id,
          text: 'Text goes here...',
          type: 'Text',
          style: {
            text: {
              fontFamily: 'System Font (Default)',
              fontSize: 24,
              fontWeight: undefined,
              fontStyle: undefined,
              textDecoration: undefined,
              align: 'center',
              color: '#605C5C',
            },
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        });
      }
      if (type === 'Image') {
        this.meta.designElements.push(
          {
            id: id,
            type: 'Image',
            url: 'https://s.xenn.io/cc2abbcb-6a68-4098-9718-10c286a4aa2c/images/5fe8b9cb-720c-4b1c-92f3-27fb4c13e109.png',
            link: undefined,
            style: {
              margin: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            },
          },
        );
      }
    },
    copy(component) {
      // eslint-disable-next-line
      const cloned = structuredClone(component);
      cloned.id = randomUtils.randomAlphabetic(5);
      this.meta.designElements.push(cloned);
    },
    remove(id) {
      // this.$confirm(
      //   {
      //     message: 'You are about to delete block. Are you sure?',
      //     button: {
      //       no: 'No',
      //       yes: 'Yes',
      //     },
      //     callback: (confirm) => {
      //       if (confirm) {
              const dropped = [];
              for (let i = 0; i < this.meta.designElements.length; i++) {
                if (id !== this.meta.designElements[i].id) {
                  dropped.push(this.meta.designElements[i]);
                }
              }
              this.meta.designElements = dropped;
      //       }
      //     },
      //   },
      // );
    },
  },
};
</script>
<style lang="scss" scoped>

div.sticky {
  position: sticky;
  bottom: 0;
}

</style>
