<template>
  <div class="mobile-preview sticky">
     <img src="/img/icons/iphone.svg" />
    <div v-if="meta.position === 'full'">
      <iframe id="mobile-preview-frame" :srcdoc="generateContent"></iframe>
    </div>
    <div v-else-if="meta.position === 'center'">
      <iframe :style="`height: ${frameSize.box.height}px`"
              id="mobile-preview-frame-center"
              :srcdoc="generateContent"></iframe>
    </div>
    <div v-else-if="meta.position === 'top'">
      <iframe :style="`height: ${frameSize.box.height}px`"
              id="mobile-preview-frame-top"
              :srcdoc="generateContent"></iframe>
    </div>
    <div v-else-if="meta.position === 'bottom'">
      <iframe :style="`height: ${frameSize.box.height}px`"
              id="mobile-preview-frame-bottom"
              :srcdoc="generateContent"></iframe>
    </div>
  </div>
</template>
<script>
import inAppNotificationsPreview from '../../services/inappnotificationsPreview';

export default {
  name: 'inAppMobilePreview',
  props: ['meta'],
  components: {
    inAppNotificationsPreview,
  },
  data() {
    return {
      frameSize: {
        box: {
          height: 100,
        },
      },
    };
  },
  methods: {
    frameMessage(event) {
      if (event.data.eventType === 'renderCompleted') {
        this.frameSize = event.data.data;
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.frameMessage, false);
  },
  computed: {
    generateContent() {
      return inAppNotificationsPreview.render(this.meta);
    },
  },
};
</script>
<style lang="scss" scoped>
#mobile-preview-frame {
  border: none;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12.5px, rgba(0, 0, 0, 0.15) 0px 0px 2.5px;
  border-radius: 8px;
  width: calc(((100% - 36px) - 0px) - 0px);
  left: 18px;
  height: calc(((100% - 30px) - 10px) - 36px);
  top: 48px;
}

#mobile-preview-frame-center {
  border: none;
  max-height: 800px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12.5px, rgba(0, 0, 0, 0.15) 0px 0px 2.5px;
  border-radius: 8px;
  width: calc(((100% - 36px) - 0px) - 0px);
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

#mobile-preview-frame-bottom {
  border: none;
  max-height: 800px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12.5px, rgba(0, 0, 0, 0.15) 0px 0px 2.5px;
  border-radius: 8px;
  width: calc(((100% - 36px) - 0px) - 0px);
  left: 18px;
  top: 50%;
  transform: translateY(-10%);
}

#mobile-preview-frame-top {
  border: none;
  max-height: 800px;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12.5px, rgba(0, 0, 0, 0.15) 0px 0px 2.5px;
  border-radius: 8px;
  width: calc(((100% - 36px) - 0px) - 0px);
  left: 18px;
  top: 5%;
}

.mobile-preview {
  background-repeat: no-repeat;
  height: 850px;
  display: flex;

  img {
    background-repeat: no-repeat;
    width: 422px;
    height: 850px;
    z-index: 1;
    pointer-events: none;
  }

  div {
    position: absolute;
    background-color: rgb(247, 247, 247);
    border-radius: 30px;
    overflow: hidden;
    width: 376px;
    height: 812px;
    margin-top: 20px;
    margin-left: 23px;
  }
}

div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
</style>
