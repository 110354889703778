<template>
  <div class="pn-platforms">
    <div class="head">
      <b-form-checkbox v-model="isChecked" switch size="lg">
        Send to <b>Apple</b>
      </b-form-checkbox>
    </div>
    <div class="content" v-if="isChecked">
      <!-- <base-input label="Content URL" placeholder="https:// or deeplink://" v-model="values.contentUrl" /> -->
      <base-input label="Sound" v-model="values.sound" placeholder=".wav .aiff .caf" />
      <div class="form-group">
        <p class="fs-14 bold mb-2">Delivery Type</p>
        <b-form-checkbox label="Sound" v-model="values.silent">Silent</b-form-checkbox>
      </div>
      <div class="form-group">
        <p class="fs-14 bold mb-2">Interruption Level</p>
        <el-select v-model="values.interruptionLevel" filterable class="no-border w-100">
          <el-option v-for="o in interruptionLevels" :key="o.value" :label="o.label" :value="o.value" />
        </el-select>
      </div>
      <base-input label="Badge" v-model="values.badge.value" :disabled="isBadgeCountDisabled">
        <template slot="prepend">
          <el-select v-model="values.badge.type" filterable class="no-border w-100">
            <el-option v-for="o in badges" :key="o.value" :label="o.label" :value="o.value" />
          </el-select>
        </template>
      </base-input>
      <div class="form-group">
        <p class="fs-14 bold mb-2">Relevance Score</p>
        <el-select v-model="values.score" filterable class="no-border w-100">
          <el-option v-for="o in scores" :key="o.value" :label="o.label" :value="o.value" />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: ['payload', 'checked'],
  created() {
    if (this.checked) {
      this.$emit('update', {
        option: this.option,
        values: this.values,
      });
    }
  },
  data: () => ({
    isInit: true,
    option: 'iosApp',
    isChecked: false,
    interruptionLevels: [{
      value: 'active',
      label: 'Active',
    }, {
      value: 'passive',
      label: 'Passive',
    }, {
      value: 'timeSensitive',
      label: 'Time Sensitive',
    }, {
      value: 'critical',
      label: 'Critical',
    }],
    badges: [{
      value: 'dontSet',
      label: 'Don\'t set',
    }, {
      value: 'setTo',
      label: 'Set to',
    }, {
      value: 'increaseBy',
      label: 'Increase by',
    }],
    scores: [{
      value: 0,
      label: '0 (lowest priority)',
    }],
    values: {
      contentUrl: '',
      sound: '',
      silent: false,
      interruptionLevel: '',
      score: 0,
      badge: {
        type: '',
        value: null
      },
    },
  }),
  computed: {
    isBadgeCountDisabled() {
      return !(this.values.badge.type.length && this.values.badge.type !== 'dontSet');
    }
  },
  watch: {
    payload: {
      deep: true,
      handler(val) {
        if (this.isInit) {
          this.checked = !!val[this.option];

          Object.keys(this.values).forEach((key) => {
            if (val[this.option]) this.$set(this.values, key, val[this.option][key]);
          });

          this.isInit = false;
        }
      },
    },
    checked: {
      immediate: true,
      handler(val) {
        this.isChecked = val;
      },
    },
    isChecked(val) {
      this.$emit('update', {
        option: this.option,
        values: val ? this.values : null,
      });
    },
    values: {
      deep: true,
      handler(val) {
        this.$emit('update', {
          option: this.option,
          values: val,
        });
      },
    },
    'values.badge.type'(val) {
      if (val === 'dontSet') {
        this.values.badge.value = null;
      }
    },
  },
};
</script>
